export class UserDTO {
  email: string | undefined | null = null;
  name: string | undefined | null = null;
  surname: string | undefined | null = null;
  number: number | undefined | null = null;
  address: string | undefined | null = null;
  password: string | undefined | null = null;
  refferal: any;
  reffered_by: any;
}

export class ActivationDTO {
  code: string | undefined | null = null;
}

export class RefferalDTO {
  refId: string | undefined | null = null;
}
