import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivationDTO, RefferalDTO, UserDTO } from '../models/user.model';

@Injectable()
export class RegisterService {
  constructor(private http: HttpClient) {}

  myProfile(token: any): Observable<any> {
    return this.http.get('https://api.centerpodjetnikov.si/api/auth/me', {
      headers: new HttpHeaders().set('x-access-token', token),
    });
  }

  findRefferal(code: RefferalDTO): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/refferee',
      code
    );
  }

  activateAccount(code: ActivationDTO): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/activate',
      code
    );
  }

  resendActivationCode(code: ActivationDTO): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/resendActivation',
      code
    );
  }

  login(account: UserDTO): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/login',
      account
    );
  }

  addUser(user: UserDTO): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/register',
      user
    );
  }

  getMyInvestments(token: any): Observable<any> {
    return this.http.get(
      'https://api.centerpodjetnikov.si/api/auth/myInvestments',
      {
        headers: new HttpHeaders().set('x-access-token', token),
      }
    );
  }

  withdrawal(investment: any, token: any): Observable<any> {
    return this.http.post(
      'https://api.centerpodjetnikov.si/api/auth/withdrawal',
      investment,
      {
        headers: new HttpHeaders().set('x-access-token', token),
      }
    );
  }
}

// 'http://api.centerpodjetnikov.si/api/auth/register
